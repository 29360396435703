<template>
    <div class="view">
        <div class="row">
            <div class="text-left">
                <div class="philo-text">
                    <p> Ace Enterprise Innovations specializes in Enterprise Engineering, which is the body of knowledge,
                        principles, and practices that involve managing complex systems of people, resources, information,
                        and technology.
                    </p>
                </div>
            </div>
            <div class="image image1">
            </div>
        </div>
        <div class="row">
            <div class="image image2">
            </div>
            <div class="text-left">
                <div class="philo-text">
                    <p>
                        At our core, we are a small business dedicated to helping our clients overcome big
                        industry challenges. It is this entrepreneurial spirit and our commitment to utilizing the
                        power of technology that allows us to deliver the complex solutions that define us.
                        Whether we're automating mundane tasks or creating integrated data management
                        tools, we prioritize supporting our clients' core goals to help them execute their work
                        more quickly and efficiently than they thought possible. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="text-left">
                <div class="philo-text">
                    <p>
                        Our team has a wide range of skills, including software development, data analysis,
                        project planning, and project management and we are committed to using these talents
                        to deliver exceptional results to our clients. Our primary focus being on building lasting
                        partnerships to ensure our customers long-term success. <br> <br>
                        Explore our website to learn more about our expertise and the industries we serve, and
                        check out our public web applications to see how we've helped our clients achieve their
                        goals in the past.
                    </p>
                </div>
            </div>
            <div class="image image3">
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "ProjectView",
    props: {
        msg: String,
    },
};
</script>

<style scoped> .view {
     position: relative;
     background-position: center;
     border: 1px red solid;
 }

 .row {
     display: flex;
     position: relative;
     align-items: center;
     /* Center items vertically */
 }


 .text-left {
     position: absolute;
 }

 .philo-text p {
     font-family: Corbel, FangSong, Euphemia, Sitka Text;
     color: white;
     font-size: 2em;
 }


 .image {
     flex: 1;
     justify-content: center;
     align-items: center;
     margin: 0%;
     padding: 0%;
     background-attachment: fixed;
 }

 .image1 {
     background-image: url("../assets/A-back.jpg");
     background-attachment: fixed;
     height: 550px;
     background-size: cover;
     background-position: center center;
 }

 .image2 {
     background-image: url("../assets/C-back.jpg");
     background-attachment: fixed;
     height: 550px;
     background-size: cover;
     background-position: center center;
 }

 .image3 {
     background-image: url("../assets/E-back.jpg");
     background-attachment: fixed;
     height: 550px;
     background-size: cover;
     background-position: center center;
 }

 @media screen and (max-width: 1024px) {

     .philo-text p {
         font-size: 1.5em;
     }
 }

 @media screen and (max-width: 512px) {

     .philo-text p {
         font-size: 1.0em;
     }
 }

 /***********************************************************************/
</style>